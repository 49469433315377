import React from "react";
import styles from "./css/NetworkForm.module.css";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import useEquityAppApi from "@/hooks/use-equity-app-api";
import { toast } from "react-toastify";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import { EmailInput, TextInput } from "@/components/UI/FormFields/Inputs";
import { useMutation } from "react-query";
import SubmitButton from "@/components/UI/SubmitButton";
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";

const joinNetworkSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  business_name: Yup.string().required("This field is required"),
  email: Yup.string().email("Invalid email").required("This field is required"),
});

const NetworkForm = () => {
  const client = useEquityAppApi();
  const mutation = useMutation(({ client, data }) => client.post("/join_pearl/", data));
  const msg =
    "Your request was sent successfully. we will reach to you as soon as possible.";

  const joinNetworkHandler = async (values, { setStatus, setErrors }) => {
    setStatus({ formLevelError: null });
    try {
      await mutation.mutateAsync({ client, data: values });
      toast.success(msg);
    } catch (error) {
      handleMutationError(error, setStatus, setErrors);
    }
  };

  return (
    <div className={`col-xs-12 col-sm-5 ${styles["net-form"]}`}>
      <div className={`card ${styles.card}`}>
        <div className="card-body">
          <h5 className={styles.heading}>Not a member of the Pearl Network?</h5>
          <p>
            Register your interest below and one of our Contractor Sales Executives will
            be in touch.
          </p>
          <Formik
            validationSchema={joinNetworkSchema}
            onSubmit={joinNetworkHandler}
            initialValues={{ name: "", business_name: "", email: "" }}
          >
            {(formik) => (
              <FormikForm>
                <FormLevelError error={formik.status?.formLevelError} />
                <TextInput label="Name" name="name" shadow={false} />
                <TextInput label="Business Name" name="business_name" shadow={false} />
                <EmailInput
                  label="Email"
                  name="email"
                  id="email_join_network"
                  shadow={false}
                />
                <SubmitButton
                  className={`btn btn-success btn-block ${styles.button}`}
                  isLoading={formik.isSubmitting}
                />
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default NetworkForm;
