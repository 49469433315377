import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import styles from "./SubmitButton.module.css";

const SubmitButton = ({ label, isLoading, ...props }) => {
  return (
    <Button className={styles.submit} type="submit" disabled={isLoading} {...props}>
      <span>{label || "Submit"}</span>
      {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
    </Button>
  );
};

export default SubmitButton;
