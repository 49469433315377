import { FormikProvider, useFormik } from "formik";
import styles from "./common/Login.module.css";
import React, { useState } from "react";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import * as Yup from "yup";
import Login from "./common/Login";
import getTokens from "@/helpers/login/login";
import { authActions } from "@/store/auth-slice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const initialValues = {
  email: "",
  password: "",
};
const schema = Yup.object().shape({
  email: Yup.string().required("This field is required"),
  password: Yup.string().required("This field is required"),
});
const LoginFormContainer = () => {
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      getTokens(values.email, values.password)
        .then((response) => {
          if (response.error) {
            setError(response.error);
          }
          if (response.data) {
            dispatch(authActions.login(response.data));
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });
  const location = useLocation();
  let loginText =
    "Access to the Solar Equity Calculator is a complimentary benefit of being in the Pearl Network.";
  if (location.pathname === "/appraiser-login") {
    loginText =
      "Access to the Solar Equity Calculator is a complimentary for all Appraisers.";
  }

  return (
    <div className={`card border-0 ${styles.card}`}>
      <div className="card-body">
        <h5 className={`fw-bold ${styles["text-primary"]}`}>
          Pearl Network Secure Login
        </h5>
        <p>{loginText}</p>
        <FormikProvider value={formik}>
          <FormLevelError error={error} />
          <Login />
        </FormikProvider>
      </div>
    </div>
  );
};
export default LoginFormContainer;
