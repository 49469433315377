import React from "react";
import arrowCircleRight from "@/assets/icons/arrowCircleRight.svg";
import loginImg from "@/assets/imgs/login_bg.svg";
import styles from "./css/Welcome.module.css";
function Welcome(props) {
  return (
    <div className={`col-xs-12 px-4 col-md-6 col-lg-6 ${styles.container}`}>
      <img className={styles["bg_img"]} src={loginImg} alt="logo" />
      <h1 className={styles.heading}>Welcome to the Pearl Solar Equity Calculator</h1>
      <hr className={styles.hr} />
      <p className={styles["text-primary"]}>
        Show your customers how much value a solar installation can add to their home when
        they refinance or sell.
      </p>
      <p className={styles["text-primary"]}>
        Our calculator currently shows the value of a solar installation, but we will be
        adding new high-performing home features over time including energy storage,
        heating and cooling, insulation and more.{" "}
      </p>
      <div className="d-flex justify-content-sm-start justify-content-center align-items-center flex-wrap">
        <a
          href={process.env.REACT_APP_BASE_URL + "/contractors/pearl-equity-calculator"}
          className={`btn ${styles["join-btn"]}`}
        >
          Join the Network <img className="ms-1" src={arrowCircleRight} alt="icon" />
        </a>
        <button
          className={`btn btn-primary ${styles["member-login"]}`}
          onClick={() => props.setShow(true)}
        >
          Members Login
        </button>
        <a
          href={process.env.REACT_APP_BASE_URL + "/about-pearl"}
          className={`my-3 mx-2 ${styles["learn-more-link"]}`}
        >
          Learn More About Pearl Certification
        </a>
      </div>
    </div>
  );
}

export default Welcome;
