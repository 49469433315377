import React from "react";
import styles from "./css/Overview.module.css";
function Overview() {
  return (
    <div className={`col-xs-12 col-sm-7 p-0 ${styles.overview}`}>
      <div className={styles.bg_overview}></div>
      <div className={styles.action}>
        <h3>We make solar installation an easy sell for our contracting partners.</h3>
        <a
          href={process.env.REACT_APP_BASE_URL + "/contractors"}
          className={`btn btn-primary ${styles.button}`}
        >
          Pearl Network Overview
        </a>
      </div>
    </div>
  );
}

export default Overview;
