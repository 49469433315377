import LoginForm from "@/components/Login/LoginForm";
import NetworkForm from "@/components/Login/NetworkForm";
import Overview from "@/components/Login/Overview";
import Partners from "@/components/Login/Partners";
import React from "react";
import Welcome from "@/components/Login/Welcome";
import useWindowSize from "@/hooks/use-window-size";

const Login = () => {
  const [width] = useWindowSize();
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    setShow((bool) => (bool && width >= 768 ? false : bool));
  }, [width]);
  return (
    <>
      <div className="row">
        <div className="row mx-2 col-12 px-0 align-items-center justify-content-center">
          <Welcome setShow={setShow} />
          <LoginForm show={show} setShow={setShow} />
        </div>
        <div className="row flex-wrap mx-2 col-12 px-0">
          <Overview />
          <NetworkForm />
        </div>
        <Partners />
      </div>
    </>
  );
};

export default Login;
