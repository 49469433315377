import * as Yup from "yup";
import { Form as FormikForm, Formik } from "formik";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import { EmailInput, PasswordInput, TextInput } from "@/components/UI/FormFields/Inputs";
import SubmitButton from "@/components/UI/SubmitButton";
import React from "react";
import Select from "@/components/UI/FormFields/Select";
import DatepickerField from "@/components/UI/FormFields/DatepickerField";
import CheckBox from "@/components/UI/FormFields/CheckBox";
import SignUpWithPearl from "./SignUpWithPearl";
import { cleanAppraiserData, useAppraiserSignUp } from "@/hooks/use-appraiser-signup";
import { useDispatch, useSelector } from "react-redux";
import {modalActions} from "@/store/modal-slice"
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";
import { Col, Row } from "react-bootstrap";
import styles from "./SignupForm.module.css";
import { useState } from "react";
import stateChoices from "@/helpers/stateChoices"

const signUpSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required"),
  last_name: Yup.string().required("This field is required"),
  email: Yup.string().email("Invalid email").required("This field is required"),
  password1: Yup.string().required("This field is required").min(8),
  password2: Yup.string()
    .oneOf([Yup.ref("password1"), null], "Passwords must match")
    .required("This field is required")
    .min(8),
  license_number: Yup.string().required("This field is required"),
  state: Yup.string().required("This field is required"),
  expiration_date: Yup.mixed().required("This field is required"),
  agree_terms: Yup.boolean().oneOf(
    [true],
    "You must agree on terms and conditions to sign up"
  ),
});

const SignUpForm = () => {
  const mutation = useAppraiserSignUp();
  const dispatch = useDispatch();
  const showSignUpWithPearl = useSelector(({ modal }) => modal.showSignUpWithPearl);
  const setShowSignUpWithPearl = (showValue) => dispatch(modalActions.setShowSignUpWithPearl(showValue))
  const signUpWithPearlFileds = ["email", "license_number", "state", "agree_terms", "expiration_date"]
  const [initialSignUpWithPearlValues, setInitialSignUpWithPearlValues] = useState(signUpWithPearlFileds.reduce((acc, field) => {
    acc[field] = ""
    return acc
  }, {}));
  const signUpHandler = async (values, { setStatus, setErrors }) => {
    const data = cleanAppraiserData(values);
    try {
      await mutation.mutateAsync({
        data: data,
        dispatch: dispatch,
      });
    } catch (error) {
      const emailErrors = error.response.data.email
      const uniqueInCurrentAppError = emailErrors.find(err => typeof err === "object" && err.code === "unique_in_current_app")
      if (uniqueInCurrentAppError) {
        setInitialSignUpWithPearlValues(signUpWithPearlFileds.reduce((acc, field) => {
          acc[field] = values[field]
          return acc
        }, {}))
        uniqueInCurrentAppError.message = (
          <div>
            <span className={styles["mr-1"]}>{uniqueInCurrentAppError.message}</span>
            Click
            <span onClick={() => setShowSignUpWithPearl(true)} className={styles["link-open-modal"]}>here</span>
            to use the same email.
          </div>
        )
      }
      handleMutationError(error, setStatus, setErrors);
    }
  };

  return (
    <>
      <Formik
        validationSchema={signUpSchema}
        onSubmit={signUpHandler}
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          password1: "",
          Password2: "",
          license_number: "",
          state: "",
          expiration_date: "",
          agree_terms: false,
        }}
      >
        {({ status, isSubmitting }) => (
          <FormikForm>
            <FormLevelError error={status?.formLevelError} />
            <Row>
              <Col md={6}>
                <TextInput
                  label="First Name"
                  name="first_name"
                  shadow={false}
                  required={true}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  label="Last Name"
                  name="last_name"
                  shadow={false}
                  required={true}
                />
              </Col>
            </Row>
            <EmailInput name="email" label="Email Address" shadow={false} required={true} />
            <PasswordInput
              name="password1"
              label="Password"
              shadow={false}
              required={true}
            />
            <PasswordInput
              name="password2"
              label="Confirm Password"
              shadow={false}
              required={true}
            />
            <TextInput
              label="Certification / License Number"
              name="license_number"
              shadow={false}
              required={true}
            />
            <Row>
              <Col md={6}>
                <Select
                  label="State"
                  name="state"
                  shadow={false}
                  choices={stateChoices}
                  required={true}
                />
              </Col>
              <Col md={6}>
                <DatepickerField
                  label="Expiration Date (MM/DD/YY)"
                  name="expiration_date"
                  placeholderText="MM/DD/YYYY"
                  dateFormat="MM/dd/yyyy"
                  data-date-format="yyyy-mm-dd"
                  required={true}
                  shadow={false}
                />
              </Col>
            </Row>
            <CheckBox
              name="agree_terms"
              label={
                <span>
                  I agree to the &nbsp;
                  <a
                    href="https://res.cloudinary.com/hyxuashac/image/upload/v1632487893/corporate/Legal/August_2021_Terms_and_Conditions_Appraisers_v1_1.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.link}
                  >
                    terms and conditions
                  </a>
                </span>
              }
            />
            <SubmitButton
              variant="primary"
              className={`btn-block`}
              label="Submit Registration"
              isLoading={isSubmitting}
            />
          </FormikForm>
        )}
      </Formik>
      <SignUpWithPearl show={showSignUpWithPearl} setShow={setShowSignUpWithPearl} initialValues={initialSignUpWithPearlValues} />
    </>
  );
};

export default SignUpForm;
